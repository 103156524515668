import React, { useMemo } from 'react'

import { Select } from '@kenect-ut/kenect-ui-kit'

import SubtitleSmall from '../common/components/typography/SubtitleSmall'
import type { LocationTeam } from '../types'
import styles from './ContactDepartment.module.scss'

export type ContactDepartmentProps = {
  teams?: LocationTeam[]
  onChangeTeam: (teamId: number) => void
  disabled: boolean
  selectedTeamId?: number
}

interface IOption {
  label: string
  value: number
}

const initialValue = {
  label: 'Select Department',
  value: -1,
}

const teamToOption = (t: LocationTeam) => ({ label: t.name, value: t.id })
const findOption = (teams?: LocationTeam[], selectedTeamId?: number) => {
  if (teams && selectedTeamId) {
    const found = teams.find((t) => t.id === selectedTeamId)
    if (found) {
      return teamToOption(found)
    }
  }
  return initialValue
}

function ContactDepartment({
  teams,
  onChangeTeam,
  disabled,
  selectedTeamId,
}: ContactDepartmentProps) {
  const leadDepartment = useMemo(
    () => findOption(teams, selectedTeamId),
    [teams, selectedTeamId],
  )

  const handleSetLeadDepartment = (chosenOpt: IOption) => {
    onChangeTeam(chosenOpt.value)
  }

  const formattedTeamOptions = useMemo(
    () => teams?.map(teamToOption) || [],
    [teams],
  )

  return (
    <>
      <SubtitleSmall className={styles.blockHeader}>Department</SubtitleSmall>
      <div className={styles.contentWrapper}>
        <Select
          id="contactDepartment"
          label=""
          value={leadDepartment}
          onChange={handleSetLeadDepartment}
          disabled={disabled}
          options={formattedTeamOptions}
          labelClassName={styles.removeLabel}
          className={styles.selectContainerReset}
          reactSelectProps={{
            isMulti: false,
            isSearchable: false,
            isClearable: false,
          }}
        />
      </div>
    </>
  )
}

export default ContactDepartment

import React from 'react'

import { HelpText } from '@kenect-ut/kenect-ui-kit'

type Props = {
  useSpacer?: boolean
  error?: string
  warning?: string
}

function ValidationMessage({ useSpacer = false, error, warning }: Props) {
  const showError = !!error
  const showWarning = !!warning && !showError
  const showSpacer = !showError && !showWarning && useSpacer

  return (
    <HelpText color="var(--red-3)">
      <>
        {showError && <div className="ml3 mt1 error-message">{error}</div>}
        {showWarning && <div className="ml3 mt1">{warning}</div>}
        {showSpacer && <div className="mt1">&nbsp;</div>}
      </>
    </HelpText>
  )
}

export default ValidationMessage

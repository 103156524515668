import { useEffect, useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'
import {
  documentId,
  getFirestore,
  onSnapshot,
  where,
  query,
  collection,
} from 'firebase/firestore'
import debounce from 'lodash/debounce'

import { Conversation } from '../../../../types'
import { getConversationsByIds } from '../../../services/inboxService/assignedConversations'
import { getMyConversations } from '../../../services/managedConversationService/getMyConversations'
import app from '../../../utils/firebase'

export const MINE = 'MINE'
export const CONVERSATION = 'CONVERSATION'

async function getAssignedConversations() {
  const conversations = await getMyConversations()
  const ids = conversations.map((c) => c.conversationId)
  if (!ids.length) {
    return []
  }
  const conversationsById = await getConversationsByIds(ids)
  return [...new Set(ids)].reduce(
    (
      accum: Array<Conversation & { managedConversationId: number }>,
      id,
      index,
    ) => {
      if (conversationsById[id]) {
        accum.push({
          ...conversationsById[id],
          managedConversationId: conversations[index].managedConversationId,
        })
      }
      return accum
    },
    [],
  )
}

export default function useAssignedConversations() {
  return useQuery({
    queryKey: [CONVERSATION, MINE],
    queryFn: getAssignedConversations,
    throwOnError: true,
  })
}

function divideArray<T>(arr: T[], n = 10) {
  const arrays = []
  for (let i = 0; i < arr.length; i += n) {
    arrays.push(arr.slice(i, i + n))
  }
  return arrays
}

export function useSubscribeToAssignedConversations() {
  const rq = useAssignedConversations()
  const debouncedRefetch = useMemo(
    () => debounce(rq.refetch, 500),
    [rq.refetch],
  )
  useEffect(() => {
    const ids = rq.data?.map((d) => d.id.toString())
    if (!ids?.length) {
      return undefined
    }
    const idBatches = divideArray(ids)
    const unsubscribeList = idBatches.map((batch) => {
      const q = query(
        collection(getFirestore(app), 'conversations'),
        where(documentId(), 'in', batch),
      )
      return onSnapshot(q, () => {
        debouncedRefetch()
      })
    })
    return () => {
      unsubscribeList.forEach((unsubscribe) => {
        unsubscribe()
      })
    }
  }, [debouncedRefetch, rq.data])
}

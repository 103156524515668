import { useMutation, useQueryClient } from '@tanstack/react-query'

import type { Message } from '../../../../types'
import { sendConversationMessage } from '../../../services/messagingService/sendConversationMessage'
import { MESSAGES } from '../inboxService/useMessages'

interface IConversationMessageList {
  pageParams?: unknown[]
  pages: unknown[][]
}

export const useSendConversationMessage = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: sendConversationMessage,
    onMutate: async (newMessage) => {
      const messagesCacheKey = [MESSAGES, newMessage?.conversationId]
      // cancel outgoing refetch to prevent overwrites
      await queryClient.cancelQueries({
        queryKey: messagesCacheKey,
      })
      // snapshot the previous value
      const previousMessages = queryClient.getQueryData(messagesCacheKey)

      // optimistically update to the new value
      queryClient.setQueryData(
        messagesCacheKey,
        (currentMessageList?: IConversationMessageList) => {
          const modifiedNewMsg: Message = {
            id: Math.floor(Math.random() * 1e5),
            body: newMessage.data.message,
            conversationId: newMessage.conversationId,
            externalStatus: 'SENDING',
            messageKind: 'OUTGOING',
            messageType: 'SMS',
            outbound: true,
            reviewRequest: false,
            attachments: [],
            sentAt: new Date().toISOString(),
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          }
          if (!currentMessageList) {
            // Handle edge case when initiating a new conversation and we don't have anything in the cache
            return { pages: [[modifiedNewMsg]], pageParams: [undefined] }
          }
          const [firstPage, ...restOfMessageListPages] =
            currentMessageList.pages as Message[][]
          const firstPageWithNewMessage = [modifiedNewMsg, ...firstPage]
          return {
            ...currentMessageList,
            pages: [firstPageWithNewMessage, ...restOfMessageListPages],
          }
        },
      )

      return { previousMessages }
    },
    onError: (_error, { conversationId }, ctx) => {
      queryClient.setQueryData(
        [MESSAGES, conversationId],
        ctx?.previousMessages,
      )
    },
  })
}

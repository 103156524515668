import { useEffect, useMemo } from 'react'

import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import {
  CONVERSATION,
  MINE,
} from '../common/hooks/services/managedConversationService/useAssignedConversations'
import { useConversation } from '../common/hooks/services/managedConversationService/useConversation'
import useCurrentConversationId from '../common/hooks/useCurrentConversationId'
import type { Conversation } from '../types'
import {
  getContactsByIds,
  getLocationTeams,
  markAsQualified,
  upsertContact,
} from './api'
import { buildContactForUpsert, ContactFormData } from './contactForm.utils'

const BY_ID = 'BY_ID'
const CONTACT = 'CONTACT'
export const LOCATION = 'LOCATION'
const TEAMS = 'TEAMS'

// TODO: replace file with actual managed texting hooks

function useContactsByIds(contactIds?: number[]) {
  const client = useQueryClient()
  const query = useQuery({
    queryKey: [CONTACT, BY_ID, contactIds],
    queryFn: () => contactIds && getContactsByIds(contactIds),
    throwOnError: true,
    enabled: !!contactIds?.length,
  })

  useEffect(() => {
    if (query.data) {
      Object.keys(query.data).forEach((id) => {
        if (query.data?.[+id]) {
          client.setQueryData([CONTACT, +id], query.data[+id])
        }
      })
    }
  }, [query.data, client])

  return query
}

export function useContact(id?: number) {
  return useQuery({
    queryKey: [CONTACT, id],
    queryFn: async () => {
      if (id) {
        const contactsById = await getContactsByIds([id])
        return contactsById[id]
      }
      return undefined
    },
    enabled: !!id,
  })
}

export function useContactsByConversations(conversations?: Conversation[]) {
  const ids = useMemo(
    () =>
      conversations
        ?.map((c) => c.contactIds)
        .flat()
        .filter(Boolean),
    [conversations],
  )
  return useContactsByIds(ids)
}

export const useContactUpsert = (options = {}) => {
  const queryClient = useQueryClient()
  const conversationId = useCurrentConversationId()
  const { data: conversation } = useConversation(conversationId)
  const { data: contact } = useContact(conversation?.contactIds?.[0])

  const mutation = useMutation({
    mutationFn: (formData: ContactFormData) => {
      return upsertContact(
        buildContactForUpsert({
          contact: {
            ...(contact || {}),
            ...formData,
            phone: formData.phone || contact?.mainNumber.number || '',
          },
          options: {
            locationId: conversation?.locationId || 0,
            contactId: contact?.id,
          },
        }),
      )
    },
    // TODO: add contact to cache during onMutate
    ...options,
  })

  useEffect(() => {
    if (mutation.isSuccess) {
      queryClient.invalidateQueries({ queryKey: [CONTACT] })
    }
  }, [mutation.isSuccess, queryClient])

  return mutation
}

export const useLocationTeams = (locationId?: number) => {
  return useQuery({
    queryKey: [LOCATION, locationId, TEAMS],
    queryFn: async () => {
      if (!locationId) {
        return undefined
      }
      return getLocationTeams(locationId)
    },
    enabled: !!locationId,
  })
}

export const useMarkLeadAsQualified = (
  options?: Omit<
    UseMutationOptions<void, unknown, number, unknown>,
    'mutationFn'
  >,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: markAsQualified,
    onSuccess: () => {
      // This should remove the conversation from an agent's
      queryClient.invalidateQueries({ queryKey: [CONVERSATION, MINE] })
    },
    throwOnError: true,
    ...options,
  })
}

import { useQuery } from '@tanstack/react-query'

import { getCurrentUser } from '../../../services/authService/user'

const AUTH_USER = 'AUTH_USER'

export function useUser() {
  return useQuery({
    queryKey: [AUTH_USER],
    queryFn: getCurrentUser,
  })
}

import { useQuery } from '@tanstack/react-query'

import { getMessageAttachment } from '../../../services/inboxService/messages'
import { toBase64 } from '../../../utils/image'

const ATTACHMENT = 'ATTACHMENT'

export function useAttachmentImage({
  messageId,
  attachmentId,
  enabled,
}: {
  messageId?: number
  attachmentId?: number
  enabled?: boolean
}) {
  return useQuery({
    queryKey: [ATTACHMENT, messageId, attachmentId],
    queryFn: async () => {
      if (attachmentId && messageId) {
        return getMessageAttachment({ attachmentId, messageId }).then(toBase64)
      }
      return undefined
    },
    enabled: !!(enabled && messageId && attachmentId),
  })
}

import React, { useMemo } from 'react'

import debounce from 'lodash/debounce'
import { useNavigate } from 'react-router-dom'

import useAssignTeam from '../common/hooks/services/inboxService/useAssignTeam'
import { useConversation } from '../common/hooks/services/managedConversationService/useConversation'
import useCurrentConversationId from '../common/hooks/useCurrentConversationId'
import { Contact } from '../types'
import styles from './ContactContainer.module.scss'
import ContactDepartment from './ContactDepartment'
import ContactForm from './ContactForm'
import ContactNotesAndAppointment from './ContactNotes'
import ForwardLead from './ForwardLead'
import {
  useContact,
  useContactUpsert,
  useLocationTeams,
  useMarkLeadAsQualified,
} from './hooks'

function isQualified(contact?: Contact) {
  return Boolean(
    contact?.firstName?.trim() &&
      contact?.lastName?.trim() &&
      contact?.note?.replace(/\n/gm, '')?.length,
  )
}

export function ContactContainer() {
  const navigate = useNavigate()
  const conversationId = useCurrentConversationId()
  const { data: conversation } = useConversation(conversationId)
  const { data: contact } = useContact(conversation?.contactIds?.[0])
  const { data: teams, isLoading: isLoadingTeams } = useLocationTeams(
    conversation?.locationId,
  )

  const { mutateAsync: assignTeam } = useAssignTeam()
  const { mutateAsync: upsertContact } = useContactUpsert()
  const { mutateAsync: markAsQualified } = useMarkLeadAsQualified({
    throwOnError: true,
  })
  const onForwardLead = async (managedConversationId?: number) => {
    if (!managedConversationId) return
    await markAsQualified(managedConversationId)
    navigate('/dashboard')
  }

  const handleContactUpsert = useMemo(
    () => debounce(upsertContact, 1000, { leading: true }),
    [upsertContact],
  )

  const isQualifiedContact = isQualified(contact)

  return (
    <aside className={styles.container}>
      <div className={styles.contactInformationContainer}>
        <ContactForm contact={contact} onAddEditContact={handleContactUpsert} />
        <ContactDepartment
          selectedTeamId={conversation?.assignedTeamId}
          disabled={!contact || isLoadingTeams}
          teams={teams}
          onChangeTeam={(teamId) => assignTeam({ teamId, conversationId })}
        />
        <ContactNotesAndAppointment
          contact={contact}
          disabled={!contact}
          onAddEditNotes={handleContactUpsert}
        />
      </div>
      <div className={styles.buttonContainer}>
        <ForwardLead
          onForwardLead={onForwardLead}
          isQualifiedContact={isQualifiedContact}
        />
      </div>
    </aside>
  )
}

import { useEffect } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { LOCATION } from '../../../../contact/hooks'
import { LocationDetailsDto } from '../../../../types'
import { getLocationDetails } from '../../../services/locationService/getDetails'

const DETAILS = 'DETAILS'

export function useLocationDetails(locationId?: number) {
  return useQuery({
    queryKey: [LOCATION, DETAILS, locationId],
    queryFn: () => {
      if (!locationId) {
        return undefined
      }
      return getLocationDetails(locationId)
    },
    enabled: !!locationId,
  })
}

export function useLocationsDetails(ids: number[]) {
  const client = useQueryClient()
  const query = useQuery({
    queryKey: [LOCATION, DETAILS, ids],
    queryFn: async () => {
      const details = await Promise.all(ids.map(getLocationDetails))
      return ids.reduce<{ [id: number]: LocationDetailsDto }>(
        (locationById, id, index) => {
          // eslint-disable-next-line no-param-reassign
          locationById[id] = details[index]
          return locationById
        },
        {},
      )
    },
    enabled: ids.length > 0,
    throwOnError: true,
  })

  useEffect(() => {
    if (query.data) {
      Object.keys(query.data).forEach((id) => {
        client.setQueryData([LOCATION, DETAILS, +id], query.data[+id])
      })
    }
  }, [query.data, client])

  return query
}

import { useQuery } from '@tanstack/react-query'

import { getMessageAttachmentThumbnail } from '../../../services/inboxService/messages'
import { toBase64 } from '../../../utils/image'

const THUMBNAIL = 'THUMBNAIL'

export function useThumbnail({
  messageId,
  attachmentId,
}: {
  messageId: number
  attachmentId: number
}) {
  return useQuery({
    queryKey: [THUMBNAIL, messageId, attachmentId],
    queryFn: () =>
      getMessageAttachmentThumbnail({ attachmentId, messageId }).then(toBase64),
    retry: 1,
    staleTime: 60 * 60 * 1000,
  })
}

import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import { claimConversation } from '../../../services/managedConversationService/claimConversation'
import useConversationQueueSummary from '../inboxService/useConversationQueueSummary'
import useAssignedConversations from './useAssignedConversations'

export default function useClaimConversation() {
  const { refetch } = useConversationQueueSummary()
  const { refetch: refetchConversations } = useAssignedConversations()
  const navigate = useNavigate()
  return useMutation({
    mutationFn: async () => {
      const id = await claimConversation()
      await Promise.all([refetchConversations(), refetch()])
      navigate(`/dashboard/lead/${id}`)
    },
    throwOnError: true,
  })
}

import React from 'react'

import { BaseAlert, Button, BodyLarge } from '@kenect-ut/kenect-ui-kit'

import styles from './UpdateAppAlert.module.scss'

export function UpdateAppAlert({
  onClick,
  updating,
}: {
  onClick: () => void
  updating: boolean
}) {
  return (
    <div className={styles.container}>
      <BaseAlert
        color="red"
        id="alert"
        isDarkMode
        message={
          <div className={styles.message}>
            <BodyLarge>Update Available</BodyLarge>
            <Button
              id="app-update"
              onClick={onClick}
              disabled={updating}
              isLoading={updating}
            >
              Update
            </Button>
          </div>
        }
      />
    </div>
  )
}
